import { createI18n } from 'vue-i18n'
let lang = localStorage.getItem('lang')
if (lang === undefined || lang === null || lang === '') {
    lang = 'en'
}
// console.info('- - -lang', lang)
const i18n = createI18n({
    locale: "" + lang + "",
    messages: {
      en: {
        signUp: "Sign Up en!!",
        name: "Name",
        LANG_ADDRESS: "Address",
        L_LOGIN: "Login",
        L_FOLDER: "Folder"
      },
      bm: {
        signUp: "Register sini!!",
        name: "Nama",
        L_LOGIN: "Log masuk",
        LANG_ADDRESS: "Alamat",
        L_FOLDER: "Fail"
      },
      ch: {
        signUp: "账户注册",
        name: "姓名",
        LANG_ADDRESS: "地址",
        L_LOGIN: "登录",
        L_FOLDER: "文件夹"
      }
    }
})

export default i18n

