import axios from 'axios'
import auth from '@/functions/auth'
import { useRouter } from 'vue-router'

// production
axios.defaults.baseURL = 'https://app.signon.my:30000/'

// staging
// axios.defaults.baseURL = 'https://app.signon.my:30002/'

axios.defaults.withCredentials = true
axios.defaults.timeout = 20000
axios.defaults.headers.common['Content-Type'] = 'application/json'

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = "X-CSRFToken"

const axiosInstance = axios.create({
  // Custom instant default
  // xsrfCookieName: 'XSRF-TOKEN', // default
  // xsrfCookieName: 'XSRF-TOKEN',
  // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  // xsrfHeaderName: 'X-XSRF-TOKEN'
  // xsrfHeaderName: 'X-XSRF-TOKEN'
})


axiosInstance.interceptors.response.use(
  response => {
    // console.info('axiosts res', response)
    //  If the custom return code is not equal to 200,  Go back 1 Errors
    // test 340
    if (response.status !== 200) {
      return Promise.reject(new Error(response.data))
    } else {
      return response
    }
  }, error => {
    // If detected user not online will logout straightaway
    
    if (error.response) {
      // console.info('AXIOS ERR', error.response.data.status, error.response.data.message, error.response.data.error)
      console.info('AXIOS ERR', error)
    }

    let errMsg = 
      { 
        message: error.message, 
        code: error.response.data.status, 
        status: error.response.data.message,
        data: (error.response.data ? error.response.data : '')
      }
      
    return Promise.reject(errMsg)
    // return Promise.reject(error)
  }
)


export default axiosInstance 

/* const instance = axios.create({
    baseURL: 'https://signon.my:3002',
    timeout: 20000,
    withCredentials: true,
    xsrfCookieName: 'sessionId'
  })
  
export default instance
*/
